<template>
    <app-wrapper>
        <template v-slot:child>
            <div class="flex flex-wrap justify-between items-center w-90 center pv4-l pv3 mini-spacing">
                <div class="w-100">
                    <p class="f3 b">Account Transactions</p>
                    <div class="flex gap24 items-center pv4">
                        <form class="w-40">
                            <div class="flex gap24">
                                <div class="flex gap8 flex-column w-60">
                                    <div class="b">Account</div>
                                    <select name="trAccount" id="trAccount" style="padding: 13px 0; width: 100%">
                                        <option>Cash on Hand</option>
                                    </select>
                                </div>
                                <div class="flex gap8 flex-column w-60">
                                    <div class="b">Date Range</div>
                                    <div class="w-100"><input class="trialDate w-100" type="date" /></div>
                                </div>
                            </div>
                        </form>
                        <div class="flex gap24 w-40">
                            <div class="flex gap8 flex-column w-60">
                                    <div class="b">From</div>
                                    <div class="w-100"><input class="trialDate w-100" type="date" /></div>
                                </div>
                                <div class="flex gap8 flex-column w-60">
                                    <div class="b">To</div>
                                    <div class="w-100"><input class="trialDate w-100" type="date" /></div>
                                </div>
                        
                        </div>
                    </div>
                    <div class="flex gap24 items-center pv4">
                        <form class="w-40">
                            <div class="flex gap24">
                                <div class="flex gap8 flex-column w-60">
                                    <div class="b">Report Type</div>
                                    <select name="trAccount" id="trAccount" style="padding: 13px 0; width: 100%">
                                        <option>Cash on Hand</option>
                                    </select>
                                </div>
                                <div class="flex gap8 flex-column w-60">
                                    <div class="b">Contact</div>
                                    <select name="trAccount" id="trAccount" style="padding: 13px 0; width: 100%">
                                        <option>All Contact</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div>
                        <div class="flex justify-between pad1016 tbSub box-border-bottom">
                            <div class="w-30 tl f500">Date</div>
                            <div class="w-30 tl b" style="color: #1a2035">Transaction</div>
                            <div class="w-30 tr f500">Debits</div>
                            <div class="w-30 tr f500">Credits</div>
                            <div class="w-30 tr f500">Balance</div>
                        </div>
                    </div>
                    <div>
                        <div class="flex justify-between pad1016 tbSub">
                            <div class="w-30 tl f500">12/11/2023</div>
                            <div class="w-30 tl b" style="color: #1a2035">Car Maintenance</div>
                            <div class="w-30 tr f500">450,000</div>
                            <div class="w-30 tr f500">0.00</div>
                            <div class="w-30 tr f500">450,000</div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>
<script>
import { ref, onMounted } from 'vue'
import AppWrapper from '@/layout/AppWrapper'

export default {
    name: 'TrialAccount',
    components: { AppWrapper },
    setup() {
        return {}
    },
}
</script>
<style>
.trialDate {
    padding: 14px 12px 11px 12px;
    border-radius: 4px;
    border: 1px solid #e3e8ee;
}
.trialHeader {
    font-size: 28px;
    font-weight: 600;
}
.gap24 {
    gap: 24px;
}
.pad1016 {
    padding: 10px 16px;
}
.gap8 {
    gap: 8px;
}
.pad1016 {
    padding: 10px 16px;
}
.tbSub {
    color: #1a2035;
    font-size: 14px;
}
.f500 {
    font-weight: 500;
    color: #1a2035;
}
</style>
